<template>
    <div class="Founder">
        <p class="title justify">
            创始人/总经理&nbsp;&nbsp;&nbsp;&nbsp;王雪峰博士
        </p>
        <p class="p justify">
            苏州大学特聘教授，上海微系统所客座研究员。
        </p>
        <p class="p justify">
            于上海交通大学和中科院上海微系统所获得学士和博士学位。
        </p>
        <p class="p justify">
            曾任职巴西国家物理研究中心和加拿大McGill大学。担任新加坡纳米材料和器件模拟软件公司 Atomistix CTO期间，带领团队开发了新一代器件TCAD软件ATK。2009年以苏州市紧缺人才身份回国。
        </p>
        <p class="p justify">
            专注半导体材料和器件，聚焦从第一性原理到有限元，力热光电多个领域的仿真。对新材料行业工艺仿真研发具有深刻的理解和经验，在研发模拟器件的格林函数方法方面深耕多年，掌握相关核心关键技术。
        </p>
        <p class="p justify">
            曾荣获中科院自然科学一等奖，担任首届全国高等学校固体物理研究会常务理事，拥有苏州市相城区科技领军人才称号。
        </p>
    </div>
</template>

<script>
export default {
  name: 'Founder',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '复数智科创始人')
        this.$emit('isShowA', false)
      }
}
</script>
<style lang="less" scoped>
.Founder{
  margin-bottom:6.25rem;
    .title{
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 1rem!important;
    }
    p{
      margin-bottom: 0!important;
    }
}
</style>
